import React from 'react';

const Loading = () => {
  return (
    <main className='container main-content'>
      <h1>Loading data ...</h1>
    </main>
  );
};

export default Loading;
