import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ data }) => {
	return (
		<article className='thegroup-card' key={data[5]}>
			<div className='thegroup-card__img'>
				<Link to={`/view/${data[5]}`}>
					<img src={data[11]} alt={`listing brochure - ${data[4]}`} />
				</Link>
				<div className='thegroup-agent'>
					<p>
						{data[1]} <span>{data[2]}</span>
					</p>
				</div>
				<div className='remaxfirst-logo'>
					<img src='/assets/images/remax-first-logo-with-balloon.png' alt='' />
				</div>
				<div className='thegroup-card__img--small'>
					<img src={`/assets/images/${data[3]}`} alt='' />
				</div>
			</div>
			<div className='thegroup-card__content'>
				<h3>{data[4]}</h3>
				<div className='property-info'>
					<p>
						<i className='fa fa-bed' />
						<span>{data[6]} Beds</span>
					</p>
					<p>
						<i className='fa fa-bath' />
						<span>{data[7]} Baths</span>
					</p>
					<p>
						<i className='fa fa-arrows-alt' />
						<span>{data[8]} Sqft</span>
					</p>
					<p>
						<i className='fa fa-car' />
						<span>{data[9]} Parking</span>
					</p>
				</div>
				<Link to={`/view/${data[5]}`} className='btn'>
					View Brochure
				</Link>
			</div>
		</article>
	);
};

export default Card;
