import React from 'react';
import { useFetch } from '../hooks/useFetch';
import Card from './Card';
import Loading from './Loading';

const gsheetID = process.env.REACT_APP_GSHEET_ID;
const gsheetNum = process.env.REACT_APP_GSHEET_NUM;
const gsheetUrl = process.env.REACT_APP_GSHEET_URL;
// const url = `${gsheetUrl}/${gsheetID}/${gsheetNum}/public/values?alt=json`;
const url = `https://sheets.googleapis.com/v4/spreadsheets/1z-rXlasV9g2PerZz8uiVtuCEmY192U0LyJA3oWyo9i8/values/10-brochures!A2:AH982?alt=json&key=AIzaSyDZ3AzArvYe4GoYuCbAX5ZkLlb-QLZ7URM`;

const Brochures = () => {
	const { loading, data } = useFetch(url);

	if (loading) {
		return <Loading />;
	}

	return (
		<main className='container main-content'>
			<h1>The Group at RE/MAX First &mdash; Listings</h1>
			<div className='underline' />

			<section className='thegroup-listings'>
				{data &&
					data.values.map((data) => {
						// return data.gsx$active.$t === 'Yes' && <Card data={data} key={data.gsx$alias.$t} />;
						return data[13] === 'Yes' && <Card data={data} key={data[5]} />;
						console.log(data[13]);
					})}
			</section>
		</main>
	);
};

export default Brochures;
