import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../hooks/useFetch';
import Loading from './Loading';

const gsheetID = process.env.REACT_APP_GSHEET_ID;
const gsheetNum = process.env.REACT_APP_GSHEET_NUM;
const gsheetUrl = process.env.REACT_APP_GSHEET_URL;
// const url = `${gsheetUrl}/${gsheetID}/${gsheetNum}/public/values?alt=json`;
const url = `https://sheets.googleapis.com/v4/spreadsheets/1z-rXlasV9g2PerZz8uiVtuCEmY192U0LyJA3oWyo9i8/values/10-brochures!A2:AH982?alt=json&key=AIzaSyDZ3AzArvYe4GoYuCbAX5ZkLlb-QLZ7URM`;

const Brochure = () => {
	const { loading, data } = useFetch(url);

	const { alias } = useParams();

	if (loading) {
		return <Loading />;
	}

	if (data) {
		var single = data.values.find((data) => data[5] === alias);
	}

	return (
		<main className='brochure-container'>
			{single && <iframe src={single[12]} frameBorder='0' title='Property Brochure' />}
		</main>
	);
};

export default Brochure;
