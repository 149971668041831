import { useEffect, useState, useCallback } from 'react';

export const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await fetch(url);
    const data = await resp.json();
    setLoading(false);
    setData(data);
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading };
};
